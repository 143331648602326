import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ChatMessage from './ChatMessage';
import { FaMicrophone, FaPaperPlane } from 'react-icons/fa';

function ChatInterface({ chatId, isIndexing }) {
  const [chatHistory, setChatHistory] = useState([]);
  const chatHistoryRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);

  useEffect(() => {
    console.log('ChatInterface received new chatId:', chatId);
  }, [chatId]);

  const toggleRecording = async () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        await sendAudioToBackend(audioBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      // Stop all tracks in the stream
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
  };

  const sendAudioToBackend = async (audioBlob) => {
    try {
      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.wav');

      // const response = await axios.post('http://localhost:7071/api/transcribe', formData, {
      const response = await axios.post('https://dml-portal-functions.azurewebsites.net/api/transcribe', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const transcription = response.data.transcript;
      
      if (transcription) {
        handleChat(transcription);
      }
    } catch (error) {
      console.error('Error sending audio to backend:', error);
    }
  };

  const handleChat = async (message) => {
    console.log('handleChat called with message:', message);
    console.log('Current chatId:', chatId);
    
    if (!message || loading || !chatId) {
        console.log('Chat blocked because:', {
            noMessage: !message,
            isLoading: loading,
            noChatId: !chatId
        });
        return;
    }

    setLoading(true);
    const newUserMessage = { role: 'user', content: message };
    setChatHistory(prevHistory => [...prevHistory, newUserMessage]);
    setUserInput('');

    try {
        // Get last 5 messages for context
        const lastMessages = chatHistory.slice(-5);
        const conversationHistory = [...lastMessages, newUserMessage];

        console.log('Sending chat request with:', {
            query: message,
            chatId: chatId,
            history: conversationHistory
        });
        
        // const response = await axios.post('http://localhost:7071/api/chat', {
        const response = await axios.post('https://dml-portal-functions.azurewebsites.net/api/chat', {
            query: message,
            chatId: chatId,
            history: conversationHistory
        });
        
        console.log('Chat response:', response.data);
        
        setChatHistory(prevHistory => [...prevHistory, 
            { 
                role: 'assistant', 
                content: response.data.message
            }
        ]);
    } catch (error) {
        console.error('Error in chat:', error);
        setChatHistory(prevHistory => [...prevHistory, 
            { role: 'assistant', content: 'An error occurred while processing your request.', error: true }
        ]);
    }

    setLoading(false);
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = () => {
    console.log('Submit clicked with input:', userInput);
    if (userInput.trim()) {
      handleChat(userInput.trim());
    }
  };

  return (
    <div className="flex flex-col h-full bg-white rounded-lg shadow-lg overflow-hidden dark:bg-[#1e1e2f]">
      <div className="flex-1 overflow-y-auto p-4 space-y-4 dark:bg-[#1e1e2f]" ref={chatHistoryRef}>
        {chatHistory.map((msg, index) => (
          <ChatMessage key={index} message={msg} />
        ))}
        {loading && (
          <div className="flex items-center space-x-2 p-3 bg-gray-100 rounded-lg">
            <div className="flex space-x-1">
              <div className="w-2 h-2 bg-[#60495A] rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
              <div className="w-2 h-2 bg-[#60495A] rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
              <div className="w-2 h-2 bg-[#60495A] rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
            </div>
          </div>
        )}
      </div>
      
      <div className="p-4 bg-gray-100 border-t border dark:border-gray-600 dark:bg-[#2a2a40]">
        <div className="flex items-center space-x-2">
          <input 
            type="text"
            value={userInput} 
            onChange={handleInputChange}
            placeholder={isIndexing ? "Indexing in progress..." : "Ask a question..."} 
            className="flex-1 py-2 px-4 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-500 rounded-full focus:outline-none focus:ring-2 focus:ring-[#60495A] focus:border-transparent"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !loading && !isIndexing) {
                handleSubmit();
              }
            }}
            disabled={isIndexing}
          />
          <button 
            onClick={handleSubmit}
            disabled={loading || isRecording || isIndexing} 
            className="p-2 bg-[#4A90E2] text-white rounded-full hover:bg-[#357ABD] focus:outline-none focus:ring-2 focus:ring-[#4A90E2] focus:ring-offset-2 transition duration-150 ease-in-out disabled:opacity-50"
          >
            <FaPaperPlane className="w-5 h-5" />
          </button>
          <button
            onClick={toggleRecording}
            disabled={loading || isIndexing}
            className={`p-2 ${isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-[#4A90E2] hover:bg-[#357ABD]'} text-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 ${isRecording ? 'focus:ring-red-500' : 'focus:ring-[#4A90E2]'} transition duration-150 ease-in-out disabled:opacity-50`}
          >
            <FaMicrophone className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatInterface;
